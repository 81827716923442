<template>
    <Loading v-if="processing" />
    <div class="desktop-height relative mb-8 lg:mt-0 lg:mb-0 mx-4">
    <div class="text-center sm:w-full">
      <div class="py-2">
        
        <p class="w-3/4 m-auto">
          Create your new password by pressing the link below
        </p>
      </div>
      <Form
        @submit="forgotPassword"
        class="p-2"
        :validation-schema="forgotPasswordValidation"
        v-slot="{ errors }"
      >
        <div class="mb-4">
          <Field
            type="email"
            placeholder="Email"
            name="email"
            class="p-1 w-full placeholder-white form-control focus:outline-none"
            :class="{ 'form-control': true, 'is-invalid': errors.email }"
            v-model="email"
          />
          <ErrorMessage
            name="email"
            class="block lg:w-full sm:w-2/4 w-11/12 text-left text-red-500 mx-auto"
          />
        </div>
        <div class="mt-4 mb-16">
          <button class="bg-btn rap-btn" type="submit" :disabled="processing">
            Forgot password
          </button>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { Field, Form, ErrorMessage } from "vee-validate";
import API from "../../api/http";
import * as yup from "yup";
import { useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";
import Loading from '../Shared/Loading';

export default {
    name: 'ForgotPassword',
    components: {
        Field,
        Form,
        ErrorMessage,
        Loading,
    },
    setup(){
        const toast = useToast();
        return { toast };
    },
    data(){
        const schema = yup.object().shape({
        email: yup
            .string()
            .email("Enter valid email.")
            .required("Please enter your email.")
            .typeError("Please enter your email."),
        });

        return {
            forgotPasswordValidation: schema,
            processing: false
        }
    },
    methods:{
        forgotPassword(){
          this.processing = true;
            API.post('forgot-password',{email: this.email})
            .then((response) => {
                this.processing = false;
                this.toast.success(response.message);
            }).catch(() => {
              this.processing = false;
            })
        }
    }
}
</script>
